
import styled from "@emotion/styled"
import { space, flex, layout, justifyContent, alignItems, flexDirection } from "styled-system"

const propNames = [].concat(
  space.propNames,
  flex.propNames,
  layout.propNames,
  justifyContent.propNames,
  alignItems.propNames,
  flexDirection.propNames,
  ["as"]
)

const Flex = styled("div", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(space, flex, layout, justifyContent, alignItems, flexDirection)

Flex.defaultProps = {
  display: "flex",
}

export default Flex
