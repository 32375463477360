import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

const Seo = ({ url, title, description, cover }) => {
  const seo = {
    url: url ? url : "https://lapidus.se",
    title: title ? title : "Lapidus Interactive",
    description: description
      ? description
      : "Data visualisation for the public good",
    cover: cover
      ? cover
      : "https://www.datocms-assets.com/9091/1567945131-lapidus-interactive.png",
  }

  return (
    <Helmet
      defer={false}
      defaultTitle={"Lapidus Interactive"}
      titleTemplate={`%s | Lapidus Interactive`}
    >
      <html lang="en" />
      <link
        rel="shortcut icon"
        href="https://www.datocms-assets.com/9091/1570213874-li-favicon.png"
      />
      s<title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta name="twitter:card" content="summary" />
      <meta property="og:site_name" content="Lapidus Interactive" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.cover} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.cover} />
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.string,
  url: PropTypes.string,
}
