import React from "react"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

import globalStyles from "../utils/globalStyles"
import SiteHeader from "../components/SiteHeader"
import SiteFooter from "../components/SiteFooter"
import SeoHelmet from "../components/Seo"

const breakpoints = ["37.5em", "56.25em", "75em", "90rem"]

const theme = {
  colors: {
    yellow: "#FFD662",
    blue: "#4E82BD",
    navy: "#131C40",
    lightGrey: "#FBF7F2",
    darkGrey: "#888888",
    white: "#fff",
  },
  containers: {
    default: "90rem",
    xs: "48rem",
    sm: "64.5rem",
    md: "84rem",
    lg: "90rem",
  },
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`,
    xl: `@media screen and (min-width: ${breakpoints[3]})`,
  },
  fontSizes: {
    xxl: "3rem",
    xl: "2rem",
    lg: "1.5rem",
    md: "1.25rem",
    sm: "1.125rem",
    xs: "1rem",
    xxs: "0.875rem",
    // xxl: "3rem",
    // xl: "2rem",
    // lg: "1.5rem",
    // md: "1.125rem",
    // sm: "1rem",
    // xs: "0.875rem",
  },
  lineHeights: {
    default: 1.5,
    dense: 1.25,
  },
  radii: {
    pill: "99rem",
    md: "0.25rem",
    circle: "100%",
  },
  shadows: {
    md: "0 0.375rem 1rem rgba(19, 28, 64, 0.1)",
    lg: "0 0.75rem 2rem rgba(19, 28, 64, 0.1)",
  },
  buttons: {
    primary: {
      background: "#FFD662",
      color: "inherit",
      boxShadow: "0 0.375rem 1rem rgba(19, 28, 64, 0.1)",
      borderRadius: "99rem",
      paddingLeft: "4rem",
      paddingRight: "4rem",
      ":hover,:focus": {
        transform: "translateY(-0.125rem)",
        boxShadow: "0 1rem 2.5rem rgba(19, 28, 64, 0.2)",
        backgroundColor: "#FFD35A",
        color: "inherit",
      },
    },
    circle: {
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: "100%",
      width: "3rem",
      height: "3rem",
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
      transition: "background-color .2s",
      ":hover,:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
    },
    nav: {
      background: "rgba(251, 244, 242, 0)",
      borderRadius: "100%",
      width: "3rem",
      height: "3rem",
      marginLeft: "0.5rem",
      marginRight: "0.5rem",
      transition: "background-color .6s cubic-bezier(0.23, 1, 0.32, 1)",
      ":hover,:focus": {
        backgroundColor: "rgba(251, 244, 242, 1)",
      },
    },
  },

  linkStyles: {
    default: {
      textDecoration: "none",
    },
    standalone: {
      fontWeight: "600",
      textDecoration: "none",
      color: "inherit",
      ":hover, :focus": {
        color: "inherit",
      },
      
    },
    navigation: {
      color: "inherit",
      textTransform: "uppercase",
      background: "linear-gradient(to bottom, #FFD662 0%, #FFD662 100%)",
      backgroundPosition: "0 100%",
      backgroundRepeat: "repeat-x",
      backgroundSize: "0.2rem 0.3rem",
      textDecoration: "none",
      transition: "background-size .6s cubic-bezier(0.23, 1, 0.32, 1)",
      ":hover, :focus": {
        color: "inherit",
        backgroundSize: "0.25rem 3.125rem",
      },
    },
  },
  waveStyles: {
    bottom: {
      transform: "rotate(180deg)",
    },
  },
}

const title = "Lapidus Interactive"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SeoHelmet />
        <Global styles={globalStyles} />
        <SiteHeader />
        {children}
        <SiteFooter />
      </>
    </ThemeProvider>
  )
}

export default Layout
