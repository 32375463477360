
import { Link as BaseLink } from "gatsby"
import { variant, space, fontSize, fontWeight, color } from "styled-system"
import styled from "@emotion/styled"

const linkStyles = variant({
  scale: "linkStyles",
  prop: "variant",
})

const Link = styled(BaseLink)`
  color: inherit;
  ${linkStyles}
  ${space}
  ${fontSize}
  ${fontWeight}
  ${color}
`


Link.defaultProps = {
  variant: "default",
}

export default Link
