import { css } from "@emotion/core"

import AvertaRegularWoff2 from "../fonts/averta-regular.woff2"
import AvertaRegularWoff from "../fonts/averta-regular.woff"
import AvertaBoldWoff2 from "../fonts/averta-bold.woff2"
import AvertaBoldWoff from "../fonts/averta-bold.woff"
import AvertaSemiBoldWoff2 from "../fonts/averta-semibold.woff2"
import AvertaSemiBoldWoff from "../fonts/averta-semibold.woff"
// import AvertaRegularWoff2 from “../../fonts/GT-Walsheim-Regular.woff2"
// import AvertaBoldWoff from “../../fonts/GT-Walsheim-Bold.woff”
// import AvertaBoldWoff2 from “../../fonts/GT-Walsheim-Bold.woff2"

const globalStyles = css`
  @font-face {
    font-family: "Averta";
    src: url(${AvertaRegularWoff2}) format("woff2"),
      url(${AvertaRegularWoff}) format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Averta";
    src: url(${AvertaBoldWoff2}) format("woff2"),
      url(${AvertaBoldWoff}) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Averta";
    src: url(${AvertaSemiBoldWoff2}) format("woff2"),
      url(${AvertaSemiBoldWoff}) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Averta";
    src: url(${AvertaRegularWoff2}) format("woff2"),
    url(${AvertaRegularWoff}) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    font-size: 1rem;
    font-family: "Averta", system-ui, sans-serif;
    color: #131c40;
  }
  a {
    color: inherit;
  }

  .content a {
    transition: color 200ms ease;
    &:hover,
    &:focus {
      color: #4E82BD;
    }
  }

  .__react_component_tooltip.type-dark {
    background-color: #131C40;
  }
  .place-top::after {
    border-top-color: #131C40 !important;
  }
  .place-left::after {
    border-left-color: #131C40 !important;
  }
  .place-right::after {
    border-right-color: #131C40 !important;
  }
  .place-bottom::after {
    border-bottom-color: #131C40 !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .locked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  img,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`

export default globalStyles
