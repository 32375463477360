
import styled from "@emotion/styled"
import { space, grid, layout, alignItems } from "styled-system"

const propNames = [].concat(
  space.propNames,
  grid.propNames,
  layout.propNames,
  alignItems.propNames,
  ["as"]
)

const Grid = styled("div", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(space, grid, layout, alignItems)

Grid.defaultProps = {
  display: "grid",
  gridColumnGap: ["1.5rem", "2.5rem"],
  gridTemplateColumns: "repeat(12, minmax(0,1fr))",
}

export default Grid
