import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { space, color, textAlign } from "styled-system"

import Container from "../Container"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"
import Button from "../Button"

const StyledHeader = styled("header")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${space};
  z-index: 9999;
`

const Navigation = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  ${color}
`

const NavItem = styled("li")`
  ${space}
  ${textAlign}
`

const NavItemDesktop = styled("li")`
  ${space}
  ${textAlign}
`

const SiteHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClick = () => {
    setMenuOpen(!menuOpen)
  }

  const handleResize = () => {
    if (!menuOpen) return
    setMenuOpen(false)
  }

  const handleLinkClick = () => {
    if (!menuOpen) return
    setMenuOpen(false)
  }

  const headerData = useStaticQuery(graphql`
    query headerQuery {
      logo: file(name: { eq: "LI-logo" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }

      menu: file(name: { eq: "menu" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
      close: file(name: { eq: "close" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
    }
  `)

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("locked")
    } else {
      document.body.classList.remove("locked")
    }
  }, [menuOpen])

  return (
    <StyledHeader>
      <Container py="4rem">
        <Flex justifyContent="space-between" alignItems="center">
          <Link
            to="/"
            variant="stealth"
            style={{ height: "3rem" }}
            onClick={handleLinkClick}
          >
            <img
              src={headerData.logo.publicURL}
              alt="Lapidus Interactive logo"
            />
          </Link>
          <Box display={["none", null, null, "block"]}>
            <nav>
              <ul style={{ display: "flex" }}>
                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/case-studies"
                  >
                    {"Case studies"}
                  </Link>
                </NavItemDesktop>

                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/services"
                  >
                    {"Services"}
                  </Link>
                </NavItemDesktop>
                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/datastory"
                  >
                    {"Datastory"}
                  </Link>
                </NavItemDesktop>
                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/news"
                  >
                    {"News"}
                  </Link>
                </NavItemDesktop>

                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/about"
                  >
                    {"About"}
                  </Link>
                </NavItemDesktop>
                <NavItemDesktop mx="1.25rem">
                  <Link
                    variant="navigation"
                    fontSize="xxs"
                    fontWeight="600"
                    to="/contact-us"
                  >
                    {"Contact us"}
                  </Link>
                </NavItemDesktop>
              </ul>
            </nav>
          </Box>
          <Box
            display={["block", "block", null, "none"]}
            style={{
              zIndex: "99999",
            }}
          >
            <Button onClick={handleClick} variant="nav">
              <img
                src={
                  menuOpen
                    ? `${headerData.close.publicURL}`
                    : `${headerData.menu.publicURL}`
                }
                alt="Toggle navigation"
              />
              <img
                style={{ height: "0px" }}
                src={headerData.close.publicURL}
                alt="Toggle navigation"
              />
            </Button>
          </Box>

          <Navigation
            bg="#fff"
            style={{ display: menuOpen ? "block" : "none" }}
          >
            <Container py="4rem">
              <Link to="/" variant="stealth" style={{ height: "3rem" }}>
                <img
                  src={headerData.logo.publicURL}
                  alt="Lapidus Interactive logo"
                />
              </Link>
              <nav style={{ marginTop: "4rem", textAlign: "center" }}>
                <ul>
                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/case-studies"
                    >
                      {"Case studies"}
                    </Link>
                  </NavItem>
                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/services"
                    >
                      {"Services"}
                    </Link>
                  </NavItem>
                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/datastory"
                    >
                      {"Datastory"}
                    </Link>
                  </NavItem>

                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/news"
                    >
                      {"News"}
                    </Link>
                  </NavItem>
                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/about"
                    >
                      {"About"}
                    </Link>
                  </NavItem>
                  <NavItem mb="2rem">
                    <Link
                      onClick={handleLinkClick}
                      variant="navigation"
                      fontSize="sm"
                      fontWeight="600"
                      to="/contact-us"
                    >
                      {"Contact us"}
                    </Link>
                  </NavItem>
                </ul>
              </nav>
            </Container>
          </Navigation>
        </Flex>
      </Container>
    </StyledHeader>
  )
}

export default SiteHeader
