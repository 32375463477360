import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { space, color } from "styled-system"

import Container from "../Container"
import P from "../Paragraph"
import Grid from "../Grid"
import Flex from "../Flex"
import Box from "../Box"
import Button from "../Button"
import Link from "../Link"

const StyledFooter = styled("footer")`
  ${space}
  ${color}
`

const SiteFooter = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      facebook: file(name: { eq: "fb" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
      twitter: file(name: { eq: "twitter" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
      mail: file(name: { eq: "mail" }, extension: { eq: "svg" }) {
        id
        name
        publicURL
      }
    }
  `)

  return (
    <StyledFooter pt="5rem" pb="2.5rem" bg="navy">
      <Container size="sm" textAlign="center">
        <P fontSize={["sm", "lg"]} color="lightGrey" py="0.75rem">
          {
            "Would you like to discuss your idea with our team of experts? We’d be happy to set up a meeting."
          }
        </P>
        <Button
          variant="primary"
          fontSize="lg"
          as="a"
          href="mailto:hello@lapidus.se"
        >
          {"Let's chat"}
        </Button>
      </Container>
      <Container my={[0, "2.5rem", "5rem"]} color="lightGrey">
        <Grid>
          <Box
            gridColumn={["1 / span 8", "1 / span 4", "1 / span 3"]}
            order={[2, 1]}
          >
            <P fontSize={["xxs", null, "xs"]} my="0">
              {"Lapidus Interactive AB"} <br />
              {"c/o Goto10 Internetstiftelsen"} <br />
              {"Hammarby Kaj 10D"} <br />
              {"120 32 Stockolm"} <br />
              {"Sweden"}
            </P>
          </Box>
          <Box
            gridColumn={["9 / span 4", "5 / span 4", "4 / span 6"]}
            order={[2, 1]}
          >
            <Flex
              flexDirection={["column", null, "row"]}
              justifyContent="center"
            >
              <Link
                fontSize={["xxs", null, "xs"]}
                px={["0.75rem"]}
                pb={["0.25rem"]}
                to="/case-studies"
              >
                {"Case studies"}
              </Link>
              <Link
                fontSize={["xxs", null, "xs"]}
                px={["0.75rem"]}
                pb={["0.25rem"]}
                to="/services"
              >
                {"Services"}
              </Link>
              <Link
                fontSize={["xxs", null, "xs"]}
                px={["0.75rem"]}
                pb={["0.25rem"]}
                to="/news"
              >
                {"News"}
              </Link>
              <Link
                fontSize={["xxs", null, "xs"]}
                px={["0.75rem"]}
                pb={["0.25rem"]}
                to="/about"
              >
                {"About"}
              </Link>
              <Link
                fontSize={["xxs", null, "xs"]}
                px={["0.75rem"]}
                pb={["0.25rem"]}
                to="/contact-us"
              >
                {"Contact us"}
              </Link>
            </Flex>
          </Box>
          <Box
            gridColumn={["span 12", "10 / span 3", "10 / span 3"]}
            my={["2.5rem", 0]}
            order={[1, 2]}
          >
            <Flex mx="-0.5rem" justifyContent="center">
              <Button variant="circle" mx="0.75rem" as="a" href="https://www.facebook.com/datastory.org/" target="_blank" rel="noopener noreferrer">
                <img
                  src={footerData.facebook.publicURL}
                  alt="Facebook button"
                />
              </Button>
              <Button variant="circle" mx="0.75rem" as="a" href="https://twitter.com/datastory" target="_blank" rel="noopener noreferrer">
                <img src={footerData.twitter.publicURL} alt="Twitter button" />
              </Button>
              <Button variant="circle" mx="0.75rem" as="a" href="mailto:hello@datastory.org" target="_blank" rel="noopener noreferrer">
                <img src={footerData.mail.publicURL} alt="Mail button" />
              </Button>
            </Flex>
          </Box>
        </Grid>
      </Container>
      <Container size="sm" textAlign="center" mt="2.5rem">
        <P fontSize={["xxs", null, "xs"]} my="0" color="blue">
          {"© Lapidus Interactive AB"}
        </P>
      </Container>
    </StyledFooter>
  )
}

export default SiteFooter
