
import styled from "@emotion/styled"
import { color, typography, space, textAlign, fontStyle } from "styled-system"

const propNames = [].concat(
  color.propNames,
  typography.propNames,
  space.propNames,
  textAlign.propNames,
  fontStyle.propNames,
  ["as"]
)

const Paragraph = styled("p", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(color, typography, space, textAlign, fontStyle)

Paragraph.defaultProps = {
  fontSize: "sm",
  lineHeight: "default",
}

export default Paragraph
