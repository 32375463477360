
import styled from "@emotion/styled"
import { position, space, layout, grid, flex, color, border, width, textAlign, justifyContent, order, display } from "styled-system"

const propNames = [].concat(
  position.propNames,
  space.propNames,
  layout.propNames,
  grid.propNames,
  flex.propNames,
  color.propNames,
  border.propNames,
  width.propNames,
  textAlign.propNames,
  justifyContent.propNames,
  order.propNames,
  display.propNames,
  ["as"]
)

const Box = styled("div", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(position, space, layout, grid, flex, color, border, width, textAlign, justifyContent, order, display)

export default Box
