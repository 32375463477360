
import styled from "@emotion/styled"
import { space, system, textAlign, color } from "styled-system"

const propNames = [].concat(
  space.propNames,
  textAlign.propNames,
  color.propNames,
  ["as", "size"]
)

const containerWidth = system({
  size: {
    property: "maxWidth",
    scale: "containers",
  },
})

const Container = styled("div", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(space, containerWidth, textAlign, color)

Container.defaultProps = {
  size: "default",
  mx: "auto",
  px: ["1.5rem", "3rem", "6rem"],
}

export default Container