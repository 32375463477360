
import styled from "@emotion/styled"
import { buttonStyle, typography, space } from "styled-system"
import { Button as RKButton } from "reakit/Button"

const allNames = [
  ...typography.propNames,
  ...space.propNames,
  ...buttonStyle.propNames,
]

const Button = styled(RKButton, {
  shouldForwardProp: prop => {
    return allNames.indexOf(prop) === -1
  }
})`
  height: 4rem;
  border: none;
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 250ms, transform 250ms;
  cursor: pointer;
  text-decoration: none;
  ${typography}
  ${space}
  ${buttonStyle}
`

export default Button
