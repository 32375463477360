// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-project-layout-js": () => import("/zeit/7797bd43/src/layouts/project-layout.js" /* webpackChunkName: "component---src-layouts-project-layout-js" */),
  "component---src-layouts-blog-post-layout-js": () => import("/zeit/7797bd43/src/layouts/blog-post-layout.js" /* webpackChunkName: "component---src-layouts-blog-post-layout-js" */),
  "component---src-pages-404-js": () => import("/zeit/7797bd43/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/zeit/7797bd43/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("/zeit/7797bd43/src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("/zeit/7797bd43/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-datastory-js": () => import("/zeit/7797bd43/src/pages/datastory.js" /* webpackChunkName: "component---src-pages-datastory-js" */),
  "component---src-pages-index-js": () => import("/zeit/7797bd43/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/zeit/7797bd43/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-services-js": () => import("/zeit/7797bd43/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

